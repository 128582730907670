<template> 
<div class="card shadow-sm bg-white border p-8">
  <div class="text-start">
    <h3 class="font-bold">Lista de {{ route.name }}</h3>
    <span class="text-sm text-gray-500">Aqui você pode observar todos os itens relacionados aos {{
      route.name.toLowerCase() }} como
      suas respectivas ações.
    </span>
    </div>
 
  <div class="flex mb-6 justify-end mt-3">
    <router-link to="/variacao/criar">
      <Button color="primary" :text="`Criar nova variação`"></Button>
    </router-link>
  </div>
  <div class="card">
    <DataTable
          :headers="headers"
          :data="data"
          :options="options"
          :exports="true"
          color="primary"
        />
  </div>
</div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE, POST } from "../../services/api";
import { useRoute } from "vue-router";
import { isEmpty } from "../../services/validate";

export default {
  name: "Variação",
  components: {
    DataTable,
    Button,
  },

  data() {
    return {
      
    };
  },

  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Nome", value: "nome" },
      { key: "Ordem", value: "ordenar" },
      { key: "Ações", value: "" },
    ];

    const route = useRoute();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);
    const variacao = ref({
      nome: "",
      ordenar: "1",
    });

    onMounted(async () => {
      data.value = await GET("variacao");
    });

    const deleteVariacao = (item) => {
      loader.open();
      const deleteFunction = async function() {
        try {
          loader.open();
          const indexItem = data.value.indexOf(item)
          await DELETE(`variacao/${item.id}`);
          data.value.splice(indexItem, 1)
          
          loader.close();
          alert.open("Sucesso!", `Variação deletada com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
        modal.open(
        "Atenção",
        "Deseja realmente excluir essa variação?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const create = async () => {
      const validateNome = isEmpty(variacao.value.nome);
      const validateOrdenar = isEmpty( variacao.value.ordenar);
      
      if (validateNome || validateOrdenar) {
        alert.open("Atenção!", validateNome, "warning"); 
        alert.open("Atenção!", validateOrdenar, "warning"); 
      } else {
        loader.open();
        try {
          await POST("variacao", variacao.value);
          alert.open(
            "Sucesso!",
            `Variação <b>${variacao.value.nome}</b> criado com sucesso!`,
            "success"
          );

          data.value.push(variacao.value);
          loader.close();
        } catch (e) {
          alert.open("Atenção!", e, "danger");
          loader.close();
        }
      }
    };

    const options = [
      {
        icon: "pen",
        path: "/variacao/editar",
        title: "Editar Variação",
      },
      { icon: "trash", action: (param) => deleteVariacao(param), title: "Excluir Variação" },
    ];

    return { headers, data, options, route, create, variacao };
  },
};
</script>

<style>
.btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>
